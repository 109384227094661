/* @media (max-width: 920px){
.visionscreencontainer{
    background-image:url(/assets/metaverse_lady.png);
    background-repeat: no-repeat;
  background-size:100vh 100vh;
}
} */

.visionscreencontainer{
    /* background-color: black; */
    /* height: 100%;
    width: 100%; */
    /* background-image:url(/assets/metaverse_lady.png);
    background-repeat: no-repeat;
  background-size:100% 100%; */
}

.hsimage{
 /*  */
 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.tvlogo{
    height: 100%;
    width: 100%;
}
/* 
.threedtlogocontainer{
  padding-top: 0%;
} */


.tvlogocontainer{
    height: 100%;
    width: 100%;
    background-color:gold;
    text-align: center;
    position: relative;
}

.welcometo{
    color: white;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
}

.vision{
  color: white;
  text-align: center;
  padding-top: 10px;
 /* font-size: 5vh; */
 font-size: 150;
 font-weight: 900; 
}

.attv{
  /* color:yellow; */
  background: linear-gradient(to right,deepskyblue,deeppink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 /* font-size: 150; */
 font-size: 5vw;
 font-family: 'Work Sans', sans-serif;
 font-weight: 900; 
}

.visionqoute{
  /* color: cyan; */
  /* background: linear-gradient(to right,lime,cyan,deepskyblue,lime); */
  background: linear-gradient(to right,deepskyblue,deeppink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
  font-style: italic;
 /* font-size: 5vh; */
 font-size: 150;
 font-weight: 900; 
 font-size: 5vw;
 font-family: 'Work Sans', sans-serif;
 font-weight: 900; 
}

.immersiveinternet{
  background: linear-gradient(to right,cyan,red,pink,orange,yellow,cyan,green,blue,indigo,purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
  font-style: italic;
 /* font-size: 5vh; */
 font-size: 180;
 font-weight: 900; 
}


.wegonnamakeit{
  background: linear-gradient(to right,cyan,yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 10px;
  font-style: italic;
 /* font-size: 5vh; */
 font-size: 150;
 font-weight: 900; 
}

.confidentenough{
  background: -webkit-linear-gradient(yellow,deeppink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
  font-style: italic;
 /* font-size: 5vh; */
 font-size: 150;
 font-weight: 900; 
}

.veryveryconfident{
  background-image: linear-gradient(
    -225deg,
    white 0%,
    cyan 29%,
    #ff1361 67%,
    #fff800 100%
  );
  text-align: center;
   text-transform: uppercase;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation:textclip  5s linear infinite;
  /* display: inline-block; */
      /* font-size: 190px; */
      -webkit-text-fill-color: transparent;
-webkit-background-clip: text;
    padding-top: 30px;
      font-size: 5vh;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.strokedvision {
  text-align: center;
    background-clip: text;
    background-image: linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f);
    color: var(--color-background);
    font-size: var(--font-size);
    /* font-size: 20vh; */
    font-weight: var(--font-weight);
    letter-spacing: var(--letter-spacing);
    padding: calc(--stroke-width / 2);
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: var(--stroke-width);
}


@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;700;900&display=swap");

.header {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4rem);
}

.titlewrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  transform: skew(0, -10deg);
}

.toptitle {
  order: 1;
  text-align: center;
  display: block;
  color: #fff;
  font-size: clamp(1rem, 4vw, 1.5rem);
  margin-bottom: 1rem;
  padding-right: 2rem;
}

.bottomtitle {
  order: 3;
  text-align: center;
  display: block;
  color: #fff;
  font-size: clamp(1rem, 4vw, 1.5rem);
  margin-top: 2rem;
  padding-left: 2rem;
}

.sweettitle {
  order: 2;
  color: #fde9ff;
  font-weight: 900;
  text-transform: uppercase;
  font-size: clamp(3rem, 10vw, 6rem);
  line-height: 0.75em;
  text-align: center;
  text-shadow: 3px 1px 1px #4af7ff, 2px 2px 1px #165bfb, 4px 2px 1px #4af7ff,
    3px 3px 1px #165bfb, 5px 3px 1px #4af7ff, 4px 4px 1px #165bfb,
    6px 4px 1px #4af7ff, 5px 5px 1px #165bfb, 7px 5px 1px #4af7ff,
    6px 6px 1px #165bfb, 8px 6px 1px #4af7ff, 7px 7px 1px #165bfb,
    9px 7px 1px #4af7ff;

}

.disclaimer {
  width: 100%;
  height: 2rem;
  text-align: center;
  color: #fff;

}

/* .cubed{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em*20;
  height: 1em*7;
  margin-left: -(200px/2 - 1em);
  margin-top: -(100vh/2 - 2em);
  background: linear-gradient(magenta, magenta) 1em*2 1em / 1em*2 1em,
              linear-gradient(magenta 1em, pink 1em*3) 1em 1em*2 / 1em 1em*4,
              linear-gradient(magenta, magenta) 1em*4 1em*2 / 1em 1em,
              linear-gradient(pink, pink) 1em*4 1em*5 / 1em 1em,
              linear-gradient(pink, pink) 1em*2 1em*6 / 1em*2 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.12em, alpha(blue,0) 2.12em) 1em 0 / 1em*3 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 1.4em, alpha(blue,0) 1.4em) 0 1em / 1em*2 1em,
              linear-gradient(45deg, blue .7em, alpha(blue,0) .7em) 1em*4 1em / 1em 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.12em, alpha(blue,0) 2.12em) 1em 1em*5 / 1em*3 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 1.4em, alpha(blue,0) 1.4em) 1em*3 1em*4 / 1em*2 1em,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 3.7em, alpha(navy,0) 3.7em) 0 1em / 1em 1em*5,
              linear-gradient(navy, navy) 1em 0 / 1em 1em*2,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 3.7em, alpha(navy,0) 3.7em) 1em 1em*6 / 1em 1em,
              linear-gradient(navy, navy) 1em*3 1em*4 / 1em 1em*2,
              linear-gradient(magenta 1em*2, pink 1em*4) 1em*6 1em / 1em 1em*5,
              linear-gradient(pink, pink) 1em*7 1em*6 / 1em*2 1em,
              linear-gradient(magenta 1em*2, pink 1em*4) 1em*9 1em / 1em 1em*5,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 1.4em, alpha(blue,0) 1.4em) 1em*5 0 / 1em*2 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 1.4em, alpha(blue,0) 1.4em) 1em*8 0 / 1em*2 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.12em, alpha(blue,0) 2.12em) 1em*6 1em*5 / 1em*3 1em,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 4.7em, alpha(navy,0) 4.7em) 1em*5 0 / 1em 1em*6,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 4.7em, alpha(navy,0) 4.7em) 1em*8 0 / 1em 1em*6,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 3.7em, alpha(navy,0) 3.7em) 1em*6 1em*6 / 1em 1em,
              linear-gradient(magenta 1em*2, pink 1em*4) 1em*11 1em / 1em 1em*6,
              linear-gradient(magenta, magenta) 1em*11 1em / 1em*3 1em,
              linear-gradient(magenta, magenta) 1em*14 1em*2 / 1em 1em,
              linear-gradient(magenta, pink 1em*2) 1em*11 1em*3 / 1em*3 1em,
              linear-gradient(magenta -(1em*9), pink 1em*1.6) 1em*14 1em*4 / 1em 1em*2,
              linear-gradient(pink, pink) 1em*11 1em*6 / 1em*3 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.83em, alpha(blue,0) 2.83em) 1em*10 0 / 1em*4 1em,
              linear-gradient(45deg, blue .7em, alpha(blue,0) .7em) 1em*14 1em / 1em 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.12em, alpha(blue,0) 2.12em) 1em*11 1em*2 / 1em*3 1em,
              linear-gradient(45deg, blue .7em, alpha(blue,0) .7em) 1em*14 1em*3 / 1em 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.12em, alpha(blue,0) 2.12em) 1em*11 1em*5 / 1em*3 1em,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 5em, alpha(navy,0) 5em) 1em*10 0 / 1em 1em*7,
              linear-gradient(navy, navy) 1em*13 1em*2 / 1em 1em*4,
              linear-gradient(magenta 1em*2, pink 1em*4) 1em*16 1em / 1em 1em*6,
              linear-gradient(magenta, magenta) 1em*16 1em / 1em*4 1em,
              linear-gradient(magenta, pink 1em*2) 1em*16 1em*3 / 1em*3 1em,
              linear-gradient(pink, pink) 1em*16 1em*6 / 1em*4 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 3.55em, alpha(blue,0) 3.55em) 1em*15 0 / 1em*5 1em,
              linear-gradient(45deg, alpha(blue,0) .7em, blue .7em,
                                     blue 2.12em, alpha(blue,0) 2.12em) 1em*16 1em*2 / 1em*3 1em,
              linear-gradient(45deg, blue 2.83em, alpha(blue,0) 2.83em) 1em*16 1em*5 / 1em*5 1em,
              linear-gradient(45deg, alpha(navy,0) .7em, navy .7em,
                                     navy 5em, alpha(navy,0) 5em) 1em*15 0 / 1em 1em*7;
  background-repeat: no-repeat;
  transform: skewY(-12deg) scale(.9);
  transform-origin: 0 0} */
