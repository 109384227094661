.hscreencontainer{
    /* background-color: black; */
    /* height: 100%;
    width: 100%; */
    /* background-image:url(/assets/Beyond_Exhibitions_BEYOND_MONET___Canada_s_Largest_Immersive_Exp.png);
    background-repeat: no-repeat;
  background-size:cover; */
}

.hsimage{
 /*  */
 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.tvlogo{
    height: 100%;
    width: 100%;
}


.tvlogocontainer{
    height: 100%;
    width: 100%;
    background-color:gold;
    text-align: center;
    position: relative;
}

.welcometo{
    color: white;
    text-align: center;
    /* padding-top: 10px; */
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
}

.weat{
    color: white;
    text-align: center;
    padding-top: 10px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
}

.onamission{
    color: white;
    text-align: center;
    padding-top: 10px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: bold;
}

@import url('https://fonts.googleapis.com/css2?family=Baloo+Chettan+2&family=Calligraffitti&display=swap');

.newweat {
  font-family: 'Calligraffitti', cursive;
  font-weight: 700;
  font-size: 13rem;
  position: relative;
  text-shadow: -15px 5px 20px #ced0d3;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  letter-spacing: 0.02em;
  text-align: center;
  color: #F9f1cc;
  text-shadow: 5px 5px 0px #FFB650, 
    10px 10px 0px #FFD662, 
    15px 15px 0px  #FF80BF, 
    20px 20px 0px #EF5097, 
    25px 25px 0px #6868AC, 
    30px 30px 0px #90B1E0;
}

@media only screen and (max-width: 600px) {
    .newweat {
        padding-top: 180px;    
        font-family: 'Calligraffitti', cursive;
        font-weight: 700;
        font-size: 8rem;
        position: relative;
        text-shadow: -15px 5px 20px #ced0d3;
        /* top: 50%;
        left: 50%; */
        transform: translate(-50%,-50%);
        letter-spacing: 0.02em;
        text-align: center;
        color: #F9f1cc;
        text-shadow: 5px 5px 0px #FFB650, 
          10px 10px 0px #FFD662, 
          15px 15px 0px  #FF80BF, 
          20px 20px 0px #EF5097, 
          25px 25px 0px #6868AC, 
          30px 30px 0px #90B1E0;

          /* padding-bottom: 10px;     */
      }
}


.wrapper {
    /* background: #000; */
    line-height: 1;
    min-height: 100%;
    display: grid;
    place-items: center;
    min-height: calc(100vh - 16px);
  }
  
/* .multicolouredhone {
    font-family: "Exo", sans-serif;
    font-size: 15vw;
    font-weight: 900;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    margin: auto;
    text-transform: uppercase;
    background: linear-gradient(219deg, 
      #186cb8) 19%, 
      transparent 19%,transparent 20%, 
      #2a9a9f) 20%, #2a9a9f)  39%,
      transparent 39%,transparent 40%, 
      #f1b211) 40%,#f1b211) 59% ,
      transparent 59%,transparent 60%, 
      #e83611) 60%, #e83611) 79%,
      transparent 79%, transparent 80%, 
      #f9002f) 80%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .container {
    padding: 1.5rem;   
    text-align: center;
    background: radial-gradient(circle at 1.4% 1.4% ,#186cb8) .8%,transparent  .8% ), 
      radial-gradient(circle at 5.5% 3%,#2a9a9f) .45% ,transparent .45% ), 
      radial-gradient(circle at 2.5% 3.5%,#f1b211) .5% ,transparent .5% ), 
      radial-gradient(circle at 4.5% 1.2%,#e83611) .25%,transparent .25% ),
  
      radial-gradient(circle at 98% 98% ,#186cb8) .8%,transparent  .8% ), 
      radial-gradient(circle at 95% 95%,#2a9a9f) .45% ,transparent .45% ), 
      radial-gradient(circle at 94.5% 97.5%,#f1b211) .5% ,transparent .5% ), 
      radial-gradient(circle at 98.5% 95.5%,#e83611) .25%,transparent .25% );
  
  
  } */

  .multicolouredhone {
    font-family: "Exo", sans-serif;
    font-size: 15vw;
    font-weight: 900;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    margin: auto;
    text-transform: uppercase;
    background: linear-gradient(219deg, 
      #186cb8 19%, 
      transparent 19%,transparent 20%, 
      #2a9a9f 20%, #2a9a9f  39%,
      transparent 39%,transparent 40%, 
      #f1b211 40%,#f1b211 59% ,
      transparent 59%,transparent 60%, 
      #e83611 60%, #e83611 79%,
      transparent 79%, transparent 80%, 
      #f9002f 80%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .container {
    padding: 1.5rem;   
    text-align: center;
    background: radial-gradient(circle at 1.4% 1.4% ,#186cb8) .8%,transparent  .8%, 
      radial-gradient(circle at 5.5% 3%,#2a9a9f) .45% ,transparent .45% , 
      radial-gradient(circle at 2.5% 3.5%,#f1b211) .5% ,transparent .5% , 
      radial-gradient(circle at 4.5% 1.2%,#e83611) .25%,transparent .25% ,
  
      radial-gradient(circle at 98% 98% ,#186cb8) .8%,transparent  .8% , 
      radial-gradient(circle at 95% 95%,#2a9a9f) .45% ,transparent .45% , 
      radial-gradient(circle at 94.5% 97.5%,#f1b211) .5% ,transparent .5% , 
      radial-gradient(circle at 98.5% 95.5%,#e83611) .25%,transparent .25% ;
  
  
  }
  
  @media screen and (min-width: 768px) {
    .multicolouredhone {
      font-size: 6.5rem;
    }
  }



