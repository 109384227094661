.herocontainer {
    /* background-color:black; */
    height: 100%;
    /* width: 100%; */
    width: 100%;
}

.valphetatitle{
  background: linear-gradient(to right,cyan,cyan,yellow,cyan);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
  text-align: center;
  /* padding-top: 10px; */
  font-size: 5vh;
  z-index: 1;
}

.welcometo{
    background: linear-gradient(to right, #f32170,
    #ff6b08, #cf23cf, #eedd44);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
    text-align: center;
    padding-top: 10px;
    font-size: 5vh;
    z-index: 1;
}

.tvlogocontainer{
    /* background-color:black; */
    width: 100;
    /*newley added*/
    height: 100%;
}

.tvlogo{
    height: auto;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
 /* padding-top: 200px; */
 padding-top: 100px;
}

.buildingnbt{
    background: linear-gradient(to right, #f32170,
    #ff6b08, #cf23cf, #eedd44);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
    text-align: center;
    padding-top: 30px;
    font-size: 5vh;
}

.animate_character
{
    text-align: center;
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    /* #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100% */
    blue 0%,
    cyan 29%,
    purple 67%,
    orange 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation:textclip  5s linear infinite;
  /* display: inline-block; */
      /* font-size: 190px; */
      -webkit-text-fill-color: transparent;
-webkit-background-clip: text;
    padding-top: 30px;
      font-size: 5vh;
      padding-bottom: 180px;  
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.threedtlogocontainer{
    height: 100%;
    width: 100%;
}


.newwelcometo{
    font-family: 'Calligraffitti', cursive;
    font-weight: 700;
    font-size: 10rem;
    position: relative;
    text-shadow: -15px 5px 20px #ced0d3;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    letter-spacing: 0.02em;
    text-align: center;
    color: #F9f1cc;
    text-shadow: 5px 5px 0px #FFB650, 
      10px 10px 0px #FFD662, 
      15px 15px 0px  #FF80BF, 
      20px 20px 0px #EF5097, 
      25px 25px 0px #6868AC, 
      30px 30px 0px #90B1E0;

    padding-bottom: 180px;  
  }
  
  @media only screen and (max-width: 600px) {
      .newwelcometo {
          font-family: 'Calligraffitti', cursive;
          font-weight: 700;
          font-size: 6rem;
          position: relative;
          text-shadow: -15px 5px 20px #ced0d3;
          /* top: 50%;
          left: 50%; */
          transform: translate(-50%,-50%);
          letter-spacing: 0.02em;
          text-align: center;
          color: #F9f1cc;
          text-shadow: 5px 5px 0px #FFB650, 
            10px 10px 0px #FFD662, 
            15px 15px 0px  #FF80BF, 
            20px 20px 0px #EF5097, 
            25px 25px 0px #6868AC, 
            30px 30px 0px #90B1E0;
            

            padding-bottom: 180px;    
        }
  }