.card {
    padding-top: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 350px;
    margin: auto;
    text-align: center;
    font-family: arial;
    background-color: cyan;
  }
  
.title {
    color: grey;
    font-size: 18px;
  }
  
.button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
.a {
    text-decoration: none;
    font-size: 22px;
    color: black;
  }
  
/* .button:hover, a:hover {
    opacity: 0.7;
  } */


.founderspage{
    padding-top: 10px;
    /* background-color: aqua; */
    /* background-color: black; */
}  

.founders_title{
  color: white;
    text-align: center;
    padding-top: 10px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
}
.founders_name{
color: aqua;
}

.founders_title{
color: deeppink;
font-weight: 900;
font-size: 10vh;
font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.founders_internal_title{
  color: deeppink;
font-weight: 900;
font-size: 4vh;
font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.harekrishna{
color: orangered;
}

.foundersinfo{
/* color: orange; */
color: deeppink;
text-align: justify;
padding-left: 10px;
padding-right: 10px;
font-family:'Courier New', Courier, monospace;
}


