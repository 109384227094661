a.joinbutton {
    text-align: center;
    background-color: #199319;
    color: white;
    padding: 15px 25px ;
    text-decoration: none;
    border-radius: 25px;
  background: #73AD21;
  padding: 20px; 
  width: 20px;
  height: 50px; 
}


.joiningbuttonscreencontainer{
    background-color: transparent;
}

.buttoncenter{
    padding-top: 10px;
    margin: auto;
  width: 50%;
  /* border: 3px solid green; */
  /* padding: 10px; */
}