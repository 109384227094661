.investinscreencontainer{
    /* background-color: black; */
    /* height: 100%;
    width: 100%; */
    /* background-image:url(/assets/Metaverse-Agency-Miracle-Hong-Kong.png);
    background-repeat: no-repeat;
    background-size:100% 100%; */
}

.hsimage{
 /*  */
 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.tvlogo{
    height: 100%;
    width: 100%;
}

.strokedinvestin {
    text-align: center;
      background-clip: text;
      background-image: linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f);
      color: var(--color-background);
      font-size:12vh;
      font-weight: var(--font-weight);
      letter-spacing: var(--letter-spacing);
      padding: calc(--stroke-width / 2);
      -webkit-text-stroke-color: transparent;
      -webkit-text-stroke-width: var(--stroke-width);
  }

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

.strokedinvestin {
    text-align: center;
      background-clip: text;
      background-image: linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f);
      color: var(--color-background);
      font-size:6vh;
      font-weight: var(--font-weight);
      letter-spacing: var(--letter-spacing);
      padding: calc(--stroke-width / 2);
      -webkit-text-stroke-color: transparent;
      -webkit-text-stroke-width: var(--stroke-width);
  }
}

.tvlogocontainer{
    height: 100%;
    width: 100%;
    background-color:gold;
    text-align: center;
    position: relative;
}

.welcometo{
    /* color:ye;
    text-align: center;
    padding-top: 80px;
   font-size: 4.5vh;
   font-style: italic;
   font-weight: 900;
   font-family: 'Times New Roman', Times, serif; */
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
    background: linear-gradient(to right,cyan,yellow,deeppink,yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   /* font-size: 150; */
   font-size: 5vh;
   font-style: italic;
   font-weight: 900;
}
.investin{
    /* color: white;
    text-align: center;
    padding-top: 10px; */
   /* font-size: 2vh; */
   /* font-size: 100;
   font-style: italic;
   font-weight: 900; */
   background: linear-gradient(to right,cyan,yellow,deeppink,yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   /* font-size: 150; */
   font-size: 5vh;
   font-style: italic;
   font-weight: 900;
}
@media only screen and (max-width: 600px) {
.investin{
    /* color: white;
    text-align: center;
    padding-top: 10px; */
   /* font-size: 2vh; */
   /* font-size: 100;
   font-style: italic;
   font-weight: 900; */
   background: linear-gradient(to right,cyan,yellow,deeppink,yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   /* font-size: 150; */
   font-size: 2.5vh;
   font-style: italic;
   font-weight: 900;

}}