.aboutusscreencontainer{
    /* background-color: crimson;
    height: 100%;
    width: 100%; */
    /* background-image:url(/assets/metaverse_lady.png);
    background-repeat: no-repeat;
  background-size:cover; */
  /* background-color: aqua; */
  /* background-image: linear-gradient(to right,cyan,deeppink); */
  /* background-color: black; */
}

.hsimage{
 /*  */
 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.tvlogo{
    height: 100%;
    width: 100%;
}


.tvlogocontainer{
    height: 100%;
    width: 100%;
    background-color:gold;
    text-align: center;
    position: relative;
    
}

.welcometo{
    color: white;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
}

.aboutus{
color: cyan;
text-align: center;
    padding-top: 10px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
}

.revolutioncoming{
    text-align: center;
    text-transform: uppercase;
    background-image: linear-gradient(
     -225deg,
     deeppink 0%,
     cyan 29%,
     #ff1361 67%,
     #fff800 100%
   );
   background-size: auto auto;
   background-clip: border-box;
   background-size: 200% auto;
   color: #fff;
   background-clip: text;
   /* text-fill-color: transparent; */
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation:textclip  5s linear infinite;
   /* display: inline-block; */
       /* font-size: 190px; */
       -webkit-text-fill-color: transparent;
 -webkit-background-clip: text;
     padding-top: 80px;
       font-size: 5vh;
 }
 
 @keyframes textclip {
   to {
     background-position: 200% center;
   }
 }


.heretomake{
    background: -webkit-linear-gradient(yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   font-size: 15vh;
   font-style: italic;
   font-weight: 900;
}

.trilliondollarimpact{
    text-align: center;
    text-transform: uppercase;
    background-image: linear-gradient(
     -225deg,
     deeppink 0%,
     cyan 29%,
     #ff1361 67%,
     #fff800 100%
   );
   background-size: auto auto;
   background-clip: border-box;
   background-size: 200% auto;
   color: #fff;
   background-clip: text;
   /* text-fill-color: transparent; */
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation:textclip  5s linear infinite;
   /* display: inline-block; */
       /* font-size: 190px; */
       -webkit-text-fill-color: transparent;
 -webkit-background-clip: text;
     padding-top: 80px;
       font-size: 5vh;
}

.commitment{
    /* background: linear-gradient(yellow,blue,lime,cyan);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    background-image: linear-gradient(
      yellow,cyan
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
      text-align: center;
      padding-top: 80px;
     /* font-size: 5vh; */
     font-size: 150;
     font-style: italic;
     font-weight: 900;
}

.actualabout{
    background: linear-gradient(to right,cyan,deeppink,yellow);
  background-clip: text;
    -webkit-text-fill-color: transparent;
      text-align: center;
      padding-top: 20px;
     /* font-size: 5vh; */
     font-size: 150;
     font-style: italic;
     font-weight: 900;
}

.lasttagline{
    background: linear-gradient(to right,lime,cyan,yellow,lime);
   background-clip: text;
    -webkit-text-fill-color: transparent;
      text-align: center;
      padding-top: 80px;
     /* font-size: 5vh; */
     font-size: 150;
     font-style: italic;
     font-weight: 900;
}

.strokedaboutus {
  text-align: center;
    background-clip: text;
    background-image: linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f);
    color: var(--color-background);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    letter-spacing: var(--letter-spacing);
    padding: calc(--stroke-width / 2);
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: var(--stroke-width);
}





.attv{
  background: linear-gradient(deeppink,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
    padding-top: 2px;
   font-size: 8vh;
   /* font-size: 250; */
   font-style: italic;
   font-weight: 900;
   /* z-index: 1; */
}