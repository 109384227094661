.joinusscreencontainer{
    /* background-color: black; */
    /* height: 100%;
    width: 100%; */
    /* background-image:url(/assets/journey.png);
    background-repeat: no-repeat; */
    /* background-size:100% 100%; */
    /* background-size:100% 100%; */
    /* background-size:cover; */
}

.hsimage{
 /*  */
 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.tvlogo{
    align-self: center;
    height: 50%;
    width: 50%;
}

.imageholder{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 50%;
}


.tvlogocontainer{
    height: 100%;
    width: 100%;
    background-color:gold;
    text-align: center;
    position: relative;
}

.welcometo{
    color: white;
    text-align: center;
    padding-top: 80px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
}

a.joinbutton {
    -webkit-appearance: button;
  -moz-appearance: button;
    text-align: center;
    background-color: #199319;
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    border-radius: 25px;
  background: deeppink;
  padding: 20px; 
  /* width: 100px; */
  width: 100%;
  /* height: 50px;  */
  height: 100%;
}

a.internshipbutton {
    -webkit-appearance: button;
  -moz-appearance: button;
    text-align: center;
    background-color: cyan;
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    border-radius: 25px;
  background: deepskyblue;
  padding: 20px; 
  /* width: 100px; */
  width: 100%;
  /* height: 100px;  */
  height: 100%; 
}

.joinus{

  color: deeppink;
  text-align: center;
  padding-top: 10px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.feeling_excited{
  background: linear-gradient( to right,yellow,red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 /* font-size: 150; */
 font-size: 12vh;
 font-style: italic;
 font-weight: 900;
 
}

.goingallalone{
  background: linear-gradient(cyan,deeppink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 /* font-size: 150; */
 font-size: 10vh;
 font-style: italic;
 font-weight: 900;
}

.noooo{
  background: linear-gradient( to right,deeppink,orange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 font-size: 5vh;
 /* font-size: 150; */
 font-style: italic;
 font-weight: 900;
}

.feeling{
  background: linear-gradient( to right,lime,cyan,lime,deeppink);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}


.proud_to_use{
  background: linear-gradient( to right,yellow,red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.join_us_on_journey{
  background:linear-gradient( to right,cyan,yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.come_on_join_us{
  /* background: linear-gradient( to right,deeppink,cyan,red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* color: darkviolet; */
  background: linear-gradient( to right,yellow,deeppink,deepskyblue,purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.click_to_apply{

}

.or{

}

.push_yourself{


}

.why_we{
  background: -webkit-linear-gradient(deeppink,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.we_will_succeed_for_sure{
  background: linear-gradient( to right,red,yellow,deeppink,deepskyblue,purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 font-size: 4vh;
 /* font-size: 150; */
 font-style: italic;
 font-weight: 900;
}

@media only screen and (max-width: 600px) {
  .we_will_succeed_for_sure{
    background: linear-gradient( to right,red,yellow,deeppink,deepskyblue,purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 80px;
   font-size: 3.2vh;
   /* font-size: 150; */
   font-style: italic;
   font-weight: 900;
  }
}

.maybenotimmediately{
  background: linear-gradient( to right,red,yellow,deeppink,deepskyblue,purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.team_of_winners{
  background: linear-gradient( to right,yellow,lime);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.loosers_visualize{
  background:linear-gradient( to right,red,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 80px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}


.newjoinus {
  padding-top: 280px; 
  font-family: 'Calligraffitti', cursive;
  font-weight: 700;
  font-size: 13rem;
  position: relative;
  text-shadow: -15px 5px 20px #ced0d3;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  letter-spacing: 0.02em;
  text-align: center;
  color: #F9f1cc;
  text-shadow: 5px 5px 0px #FFB650, 
    10px 10px 0px #FFD662, 
    15px 15px 0px  #FF80BF, 
    20px 20px 0px #EF5097, 
    25px 25px 0px #6868AC, 
    30px 30px 0px #90B1E0;
}

@media only screen and (max-width: 600px) {
  .newjoinus  {
        padding-top: 180px;    
        font-family: 'Calligraffitti', cursive;
        font-weight: 700;
        font-size: 4rem;
        position: relative;
        text-shadow: -15px 5px 20px #ced0d3;
        /* top: 50%;
        left: 50%; */
        transform: translate(-50%,-50%);
        letter-spacing: 0.02em;
        text-align: center;
        color: #F9f1cc;
        text-shadow: 5px 5px 0px #FFB650, 
          10px 10px 0px #FFD662, 
          15px 15px 0px  #FF80BF, 
          20px 20px 0px #EF5097, 
          25px 25px 0px #6868AC, 
          30px 30px 0px #90B1E0;

          /* padding-bottom: 10px;     */
      }
}
/* 
.container {
  display: flex;
  height: 100%;
  align-items: center;
}

.svg {
  display: block;
  font: 10.5em 'Montserrat';
  width: 960px;
  height: 300px;
  margin: 0 auto;
}

.textcopy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
}

.textcopy:nth-child(1){
	stroke: #4D163D;
	animation-delay: -1;
}

.textcopy:nth-child(2){
	stroke: #840037;
	animation-delay: -2s;
}

.textcopy:nth-child(3){
	stroke: #BD0034;
	animation-delay: -3s;
}

.textcopy:nth-child(4){
	stroke: #BD0034;
	animation-delay: -4s;
}

.textcopy:nth-child(5){
	stroke: #FDB731;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
} */

@import url('https://fonts.googleapis.com/css?family=Roboto:700');

.container {
  font-family:'Roboto';
  text-align:center;
  color:yellow;
  text-transform: uppercase;
  font-size:36px;
  font-weight:bold;
  padding-top:100px;  
  position:relative;
  width:100%;
  bottom:45%;
  display:block;
}

.flip {
  font-family:'Roboto';
  text-align:center;
  height:50px;
  overflow:hidden;
}

.flip > div > div {
  color:#fff;
  padding:4px 12px;
  height:45px;
  margin-bottom:45px;
  display:inline-block;
}

.flip div:first-child {
  animation: show 5s linear infinite;
}

.flip div div {
  background:#42c58a;
}
.flip div:first-child div {
  background:#4ec7f3;
}
.flip div:last-child div {
  background:#DC143C;
}

@keyframes show {
  0% {margin-top:-270px;}
  5% {margin-top:-180px;}
  33% {margin-top:-180px;}
  38% {margin-top:-90px;}
  66% {margin-top:-90px;}
  71% {margin-top:0px;}
  99.99% {margin-top:0px;}
  100% {margin-top:-270px;}
}


/* center the blockquote in the page */
.blockquotewrapper {
  display: flex;
  height: 100vh;
  padding: 0 20px;
}

/* Blockquote main style */
.blockquote {
   position: relative;
   /* font-family: 'Barlow Condensed', sans-serif; */
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   max-width: 620px;
   margin: 80px auto;
   align-self: center;
}

/* Blockquote header */
.blockquote h1 {
   /* font-family: 'Abril Fatface', cursive; */
   font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   position: relative; /* for pseudos */
   color: #e74848;
   font-size: 2.8rem;
   font-weight: normal;
   line-height: 1;
   margin: 0;
   border: 2px solid #fff;
   border: solid 2px;
   border-radius:20px;
   padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h1:after {
   content:"";
   position: absolute;
   border: 2px solid #e74848;
   border-radius: 0 50px 0 0;
   width: 60px;
   height: 60px;
   bottom: -62px;
   left: 50px;
   border-bottom: none;
   border-left: none;
   z-index: 3; 
}

.blockquote h1:before {
   content:"";
   position: absolute;
   width: 80px;
   border: 6px solid #292a2b;
   bottom: -3px;
   left: 50px;
   z-index: 2;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
   .blockquote h1 {
       font-size: 3rem;
       line-height: 1.2;
  }

}

/* Blockquote subheader */
.blockquote h4 {
   position: relative;
   color: #ffffff;
   font-size: 1.3rem;
   font-weight: 400;
   line-height: 1.2;
   margin: 0;
   padding-top: 15px;
   z-index: 1;
   margin-left:150px;
   padding-left:12px;
}


.blockquote h4:first-letter {
 margin-left:-12px;
}