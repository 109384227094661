/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
    float: left;
    /* width: 17%;
    padding: 5px; */
    padding-left: 15%;
  }
  
  @media only screen and (max-width: 600px) {
    .column {
      float:left;
      width: 17%;
      padding: 5px;
    }
    }
  /* Clear floats after image containers */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  @media only screen and (max-width: 600px) {
.linkedinlogo{
    /* width: 100%;     */
    /* height: auto;
    width: auto;*/
    /* width: 25vw;    
  height: 15vw; */
}}

@media only screen and (max-width: 600px) {
.instagramlogo{
  width: 20vw;    
  height: 12vw;
}}

@media only screen and (max-width: 600px) {
.twitterlogo{
width: 14vw;
padding-left: 8px;
}}

@media only screen and (max-width: 600px) {
.facebooklogo{
width: 18vw;
height: 12vw;
}}

@media only screen and (max-width: 600px) {
.youtubelogo{

}
}

@media only screen and (max-width: 600px) {
.social_media_div{
    background-color: black;
}
}

.Footer{
    /* background-color: aqua; */
    background-color: black;
}

.hashthreedverse{
  background:linear-gradient( to right,red,deeppink,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 10px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.born{
  background:linear-gradient( to right,red,deeppink,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 20px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}

.followuson{
  background:linear-gradient( to right,red,deeppink,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 20px;
 /* font-size: 5vh; */
 font-size: 150;
 font-style: italic;
 font-weight: 900;
}