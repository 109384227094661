.aboutusscreencontainer{
  /* background-image: linear-gradient(cyan,yellow); */
  /* background-image: black; */
}

.hsimage{
 /*  */
 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.tvlogo{
    height: 100%;
    width: 100%;
}

.welcometo{
    color:whitesmoke;
    text-align: center;
    padding-top: 80px;
   /* font-size: 150; */
   font-size: 15vh;
   font-family: 'Work Sans', sans-serif;
   font-weight: 900;
    /* position: absolute;
    top: 50%;
    left: 50%; */ 
    /* transform: translate(-50%, -50%); */
    
}

.ourbelief{
color:deeppink;
text-align: center;
    padding-top: 70px;
   /* font-size: 5vh; */
   font-size: 150;
   font-style: italic;
   font-weight: 900;

}

.attv{
  background: linear-gradient(deeppink,yellow,cyan);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
    padding-top: 2px;
   font-size: 8vh;
   /* font-size: 250; */
   font-style: italic;
   font-weight: 900;
   /* z-index: 1; */
}

.empowering{
  text-align: center;
   text-transform: uppercase;
   background-image: linear-gradient(
    -225deg,
    deeppink 0%,
    cyan 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation:textclip  5s linear infinite;
  /* display: inline-block; */
      /* font-size: 190px; */
      -webkit-text-fill-color: transparent;
-webkit-background-clip: text;
    padding-top: 90px;
      font-size: 5vh;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Baloo+Chettan+2&family=Calligraffitti&display=swap');

.newourbelief {
  padding-top: 280px; 
  font-family: 'Calligraffitti', cursive;
  font-weight: 700;
  font-size: 13rem;
  position: relative;
  text-shadow: -15px 5px 20px #ced0d3;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  letter-spacing: 0.02em;
  text-align: center;
  color: #F9f1cc;
  /* text-shadow: 5px 5px 0px #FFB650, 
    10px 10px 0px #FFD662, 
    15px 15px 0px  #FF80BF, 
    20px 20px 0px #EF5097, 
    25px 25px 0px #6868AC, 
    30px 30px 0px #90B1E0; */
    text-shadow: 5px 5px 0px cyan, 
    10px 10px 0px deeppink, 
    15px 15px 0px yellow, 
    20px 20px 0px purple, 
    25px 25px 0px crimson, 
    30px 30px 0px yellow;
}

@media only screen and (max-width: 600px) {
    .newourbelief  {
        padding-top: 180px;    
        font-family: 'Calligraffitti', cursive;
        font-weight: 700;
        font-size: 4rem;
        position: relative;
        text-shadow: -15px 5px 20px #ced0d3;
        /* top: 50%;
        left: 50%; */
        transform: translate(-50%,-50%);
        letter-spacing: 0.02em;
        text-align: center;
        color: #F9f1cc;
        /* text-shadow: 5px 5px 0px cyan, 
    10px 10px 0px deeppink, 
    15px 15px 0px yellow, 
    20px 20px 0px purple, 
    25px 25px 0px crimson, 
    30px 30px 0px yellow; */
    

          /* padding-bottom: 10px;     */
      }
}

.battery {
  position: relative;
  margin: 0 auto;
  margin-top: 20%;
  width: 300px;
  border: 10px solid rgba(0, 255, 255, .25);;
  height: 100px;
  border-radius: 15px;
}

.battery:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  height: 88px;
  background-color: cyan;
  border-radius: 5px;
  animation: full 5s linear infinite;
}

.battery:after {
  content: "";
  position: absolute;
  right: -30px;
  top: 28px;
  width: 15px;
  height: 50px;
  background-color: rgba(0, 255, 255, .25);
  border-bottom-right-radius: 90px;
  border-top-right-radius: 90px;
}

@keyframes full {
  0% {
    width: 0%;
  }
  25% {
    width: 24%;
  }
  50% {
    width: 48%;
  }
  75% {
    width: 72%;
  }
  100% {
    width: 96%;
  }
}
